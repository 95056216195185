/* General App Container */
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; /* Full height */
  background-color: #f0f0f0;
}

.chat-interface {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* Chat Container */
.chat-container {
  left: 15%;
  bottom: 10px;
  background-color: #d9d9d9;
  height: calc(100% - 20px); /* Adjust for padding */
  align-content: end;
  flex-grow: 1;
  overflow: hidden;
  padding: 10px;
  /*box-shadow: inset 0 16px 20px 2px rgb(237 180 135);*/
}
.chat-container {
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  justify-content: flex-start; /* Add this */
}

.message-list {
  flex-grow: 1;
  overflow-y: auto; /* Allow scrolling */
  padding: 10px;
  scroll-behavior: smooth;
  margin-bottom: 10px; /* Add this */
}

/* Input Form */
.send-message-form {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 2px solid #ccc; /* Stronger border to separate input area */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1); /* Slight shadow on the input area */
  border-radius: 0 0 10px 10px; /* Rounded corners at the bottom */
  overflow: hidden;
  z-index: 1; /* Ensure it stays above other content */
  background: #d9d9d9;
}

.form-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align form content to start */
  overflow-y: auto; /* Allow form content to scroll when expanded */
}


.input-button-wrapper {
  display: flex;
  justify-content: space-between; /* Keep input and button side by side */
  align-items: center;
}

.send-message-form input {
  flex: 1;
  margin-right: 10px;
  margin-left: 10px;
  padding: 15px;
  border: none; /* Remove default border */
  border-radius: 30px; /* Make input field fully rounded */
  font-size: 1.2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  outline: none; /* Remove the default blue outline */
  transition: box-shadow 0.3s ease, border 0.3s ease; /* Smooth transition */
}

.send-message-form input:focus {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on focus */
  border: 10px solid #92159fdb; /* Add a purple border on focus */
}

.send-message-form button {
  padding: 10px 20px;
  border: none;
  font-weight: bold;
  font-size: large;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

/* Disabled button state */
.send-message-form button:disabled {
  cursor: not-allowed; /* Show "not allowed" cursor */
}

.options-container {
  display: flex;
  flex-direction: column;  /* Stack children vertically */
  align-items: center;     /* Center align the chips if desired */
  padding: 10px;           /* Add some padding around the container */
}

.option-chip.selected, .option-chip:hover {
  border: solid medium #F8E104;
  background-color: #ffb272;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chat-container {
    width: 100%; /* Full width on small screens */
  }
}

.user-app-header {
  text-align: center;
  background: #ffffff;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center; /* Center the title container */
  align-items: center;
  position: relative; /* Ensure logo can be positioned without affecting title */
  height: 45px;
}

/* Flex container for logo and title */
.header-content {
  display: flex;
  align-items: center; /* Vertically center logo and text */
  justify-content: center; /* Center them horizontally */
}

.header-logo-left {
  position: absolute;
  left: 10px; /* Align the logo to the left */
}

/* Flex container for title (centered) */
.header-title-center {
  flex-grow: 1;
  text-align: center;
}

/* Flex container for title (centered) */
.header-logo-right {
  margin-left: auto; /* Push the right logos to the right side */
  display: flex;
  gap: 0; /* Space between the two logos */
  align-items: center; /* Vertically center the logos */
}

/* Logo styling */
.header-logo {
  width: 55px; /* Adjust size as needed */
  height: 55px;
}

/* Logo styling */
.header2-logo {
  width: 55px; /* Adjust size as needed */
  height: 55px;
}

.header2-icon3 {
  width: 30px; /* Adjust size as needed */
  height: 30px;
  align-self: end;
}

/* Title styling */
.user-app-header h1 {
  font-size: 1.5rem; /* Adjust font size */
  color: white; /* White text for header */
  margin: 0;
}

/* Adjust these styles as necessary */
.chip-icon {
  width: 19px;
  height: 19px;
  margin-right: 8px; /* Adjust spacing between icon and text */
  margin-left: 8px; /* Adjust spacing between icon and text */
  vertical-align: top; /* Align icon with text */
  border: solid thin black;
}

/* Chatbot box styles */
.chatbox {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 32%; /* Adjust size as needed */
  height: 90%; /* Adjust size as needed */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Adjust existing styles to fit the box */
.chat-interface {
  height: 100%;
  background-color: #d9d9d9;
}

.chat-container {
  flex-grow: 1;
  overflow-y: auto;
}

.message-list {
  flex-grow: 1;
  overflow-y: scroll; /* Allows scrolling with a scrollbar */
  padding: 10px; /* Adjust padding as needed */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f0f0f0; /* Custom colors for scrollbar (thumb and track) */
  margin-bottom: 100px;
  scroll-behavior: smooth;
  max-height: calc(100vh - 250px);
}

/* WebKit-based browsers (Chrome, Safari) */
.message-list::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

.message-list::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
}

.message-list::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 10px; /* Round the corners */
  border: 3px solid #f0f0f0; /* Adds padding to the thumb */
}

/* Footer Links Section below input/button */
.footer-links {
  display: flex;
  justify-content: center; /* Center the links */
  gap: 15px; /* Space between each link */
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.footer-links {
  position: relative; /* Changed from margin-based positioning to relative */
  bottom: 0;
  width: 100%; /* Ensures the footer spans the entire width */
  text-align: center;
  color: black;
}

.footer-links a {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: gray; /* Darker color on hover */
}

.footer-links a:not(:last-child):after {
  content: '|';
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}
/* Minimize and Close Buttons */

.chat-header-controls {
  display: flex;
  justify-content: space-between; /* Keeps minimize to the left, close to the right */
  padding: 5px;
  background-color: #ccc;
  border-bottom: 1px solid #bbb;
  height: 40px; /* Adjust height slightly to give more space */
  align-items: center; /* Centers the buttons vertically */
}

.chat-header-controls button {
  background: none;
  border: none;
  font-size: 27px; /* Increase size for better visibility */
  padding: 0 10px;
  cursor: pointer;
}

.chat-header-controls button:first-child {
  font-size: 40px; /* Make the minimize button larger */
  padding-bottom: 35px; /* Adjust positioning slightly */
}

.chat-header-controls button:hover {
  background-color: #ddd;
}

/* User App Header */
.user-app-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure spacing between elements */
  padding: 15px 15px 15px 10px;
  border-bottom: 1px solid #ccc;
}

.header-logo-left {
  display: flex;
  justify-content: flex-start;
}

.header-title-center {
  flex-grow: 1;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.header-right-text {
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
}
.header-right-text {
  display: inline-block;
  text-align: left;
}

.header-right-text span {
  display: inline-block;
  margin-bottom: 5px; /* Add space between each row */
}

.header-value {
  display: inline-block;
  margin-left: 15px; /* Add space (or tab-like effect) between label and value */
}

/* Tabs */
.tab-container {
  display: flex;
  justify-content: space-between; /* Space between each tab */
  margin-top: 10px;
  padding: 0 20px; /* Add padding for spacing inside the container */
}

.tab {
  flex: 1; /* Make tabs take equal width */
  padding: 15px; /* Increase padding for more space inside the tab */
  background-color: #f0f0f0;
  border: 2px solid black; /* Add black border for the tab */
  border-radius: 5px;
  margin: 0 10px; /* Add margin for space between tabs */
  cursor: pointer;
  font-weight: bold;
  font-size: 19px;
  text-align: center; /* Center align text */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition */
}

.tab.active {
  background-color: white; /* Active tab background */
  border: 2px solid black; /* Active tab border */
}

.tab.disabled {
  background-color: #eee;
  color: #999;
  border-color: #ccc; /* Lighter border for disabled tabs */
  pointer-events: none; /* Disable clicking */
}

/* Wrapper for the input and send button */
.input-button-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Back button styling */
.back-button {
  padding: 10px 20px;
  background-color: #d77301;
  color: white;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  font-size: large;
  cursor: pointer;
  margin-right: 10px;
}

/* Textarea and send button wrapper */
.textarea-wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 30px;
}

.textarea-wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 30px;
  transition: height 0.3s ease-in-out;
  /* Add a fixed height limit for the textarea and allow it to grow upward */
  overflow: hidden; /* Allow overflow hidden initially */
  height: auto; /* Allow it to grow */
  max-height: none; /* Remove max-height restriction */
  min-height: 15px; /* Initial minimum height */
}

.message-input {
  flex: 1;
  padding: 15px 65px 15px 15px;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  font-family: 'Lato', sans-serif;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide scrollbar until necessary */
  height: 25px; /* Allow auto height adjustment */
  min-height: 25px; /* Minimum height as per your requirement */
  max-height: 150px; /* Set a maximum height for the textarea */
  line-height: 1.5;
}

.message-input:focus {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}



.textarea-wrapper.focused .message-input {
  height: auto; /* Allow height expansion as necessary */
  max-height: 150px; /* Set maximum height */
}


.send-arrow-button {
  position: absolute;
  right: 10px;
  top: 50%;  /* Center vertically in the textarea */
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
}

.textarea-wrapper.focused .send-arrow-button {
  opacity: 1; /* Fade in */
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, visibility 0.4s;
  visibility: visible; /* Make it visible */
  transform: translateY(-50%); /* Slide up into position */
}


.send-arrow-button img {
  width: 25px;
  height: 25px;
}

/* Error state for input */
.input-error {
  border: 2px solid red;
}

/* Footer Links */
.footer-links {
  margin-top: 10px; /* Change from 10px */
  text-align: center;
  font-size: 0.9rem;
  color: black;
}

.footer-links a {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: gray;
}

.char-counter {
  font-size: 0.9rem;
  color: gray;
  text-align: right;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  bottom: 15px; /* Moves it below the textarea */
  right: 20px; /* Aligns it to the right */
}



