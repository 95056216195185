.chatbot-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    border-color: black;
    background-color: #ffffff; /* Customize as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chatbot-icon {
    width: 70px; /* Adjust size */
    height: 70px; /* Adjust size */
}
