.option-radio {
    display: flex;
    align-items: flex-start; /* Change from center to flex-start */
    margin-bottom: 10px;
    cursor: pointer;
}

.option-radio input[type="radio"],
.option-radio input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
}

.option-radio .radio-text {
    display: inline-flex;
    flex-direction: column; /* Add this to stack the text vertically */
    align-items: flex-start; /* Add this to align the text to the start */
    color: #000;
    position: relative;
    padding-left: 35px; /* Increase padding to make room for the radio button */
}

.option-radio .radio-text::before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px; /* Adjust this value to fine-tune vertical alignment */
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    background-color: #fff;
    transition: all 0.3s ease;
    flex-shrink: 0;
}

.option-radio input[type="radio"] + .radio-text::before {
    border-radius: 50%;
}

.option-radio input[type="checkbox"] + .radio-text::before {
    border-radius: 3px;
}

.option-radio input[type="radio"]:checked + .radio-text::before,
.option-radio input[type="checkbox"]:checked + .radio-text::before {
    background-color: #000;
    border-color: #000;
}

.option-radio input[type="radio"]:checked + .radio-text::after,
.option-radio input[type="checkbox"]:checked + .radio-text::after {
    content: '';
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    left: 6px;
    top: 11px; /* Adjust this value to match the ::before pseudo-element */
    transform: none; /* Remove the translateY */
}

.option-radio input[type="radio"]:checked + .radio-text::after {
    border-radius: 50%;
}

.option-radio input[type="checkbox"]:checked + .radio-text::after {
    border-radius: 1px;
}

/* Hover effect */
.option-radio:hover .radio-text::before {
    border-color: #555;
}

.message-item {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    padding: 8px 12px;
    align-self: flex-start; /* Default alignment */
    box-shadow: 0 1px 1px rgba(0,0,0,0.05);
    width: auto; /* Adjust width as necessary, perhaps max-width: 80%; */
    margin: 8px 10px;
    border-radius: 10px;
    background-color: #f0f0f0; /* Default background for bot messages */
    clear: both; /* This ensures that each message takes a new line */
}

.user-message {
    float: right; /* Aligns user messages to the right */
    background: linear-gradient(90deg, #ffffff 0%, #ffe9cd 100%);
    padding: 10px 15px;
    border-radius: 20px 5px 20px 20px; /* Rounded corners */
    margin-bottom: 15px;
    box-shadow: -8px 14px 10px 1px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.bot-message {
    float: left; /* Keeps bot messages on the left */
    background: linear-gradient(90deg, #fe9a69 0%, #fac075 100%);/* Different background for bot messages */
    padding: 10px 15px;
    border-radius: 5px 20px 20px 20px; /* Rounded corners */
    color: #320b86;
    margin-bottom: 15px;
    box-shadow: 8px 14px 10px 6px rgba(0, 0, 0, 0.1);
}

.message-content {
    color: black;
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
}

.message-content span {
    display: block; /* Makes the span take up the full width, pushing it to a new line */
    margin-top: 4px; /* Adds space above the timestamp if needed */
}

.message-timestamp {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.95rem;
    margin-top: 2px;
    display: flex;
    align-items: center;
}

.message-timestamp .pipe {
    margin: 0 5px;
}

.option-chip {
    margin: 10px 0 10px 0;             /* Add margin to separate each chip */
    padding: 10px 0 10px 0;             /* Add margin to separate each chip */
    background-color: unset; /* Bright blue for options */
    color: black;
    border-radius: 15px;     /* Rounded corners for aesthetic */ /* Remove default border */
    cursor: pointer;
    transition: background-color 0.2s; /* Smooth transition for hover effect */
    text-align: justify;
    width: 100%;
    font-size: 25px;
    font-family: 'Lato', sans-serif;
    border: none;
    word-break: break-word;
    white-space: normal;
    display: inline-flex;              /* Use flexbox for layout */
    position: relative;
    align-items: flex-start;    /* Align items to the start to prevent vertical centering */
    box-sizing: border-box;     /* Include padding and border in the element's total width and height */
    border-bottom: 5px;
    line-height: 0.8;
}

.options-flex {
    display: flex;
    flex-direction: column;
    width: 100%;               /* Ensure the parent div takes full width */
    box-sizing: border-box;    /* Include padding and border in the element's total width and height */
    padding-top: 7px;
    padding-bottom: 10px;
}

.option-chip .chip-text {
    flex: 1;                 /* Allow the text to take up remaining space */
    word-break: break-word;  /* Break long words to fit within the width */
    white-space: normal;     /* Allow text to wrap normally */
    margin-top: 0;
    display: inline-block;
    vertical-align: top;
}

.avatar-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 6px;
}

.avatar-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px; /* Space between avatar and text */
}

.avatar-text {
    margin-top: 0;
}

.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.video-container {
    position: relative;
    width: 80%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-container-inline {
    width: 100%;
    max-width: 100%;
    padding: 35px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;

}

.video-container-inline video {
    width: 100%;
    height: auto;
    border-radius: 10px;
    max-width: 100%;
}

.close-video {
    position: absolute;
    top: 0;
    right: 20px;
    background: rgba(195 , 195 , 195);
    border: none;
    font-size: 21px;
    cursor: pointer;
    color: #000;
    z-index: 10;
    padding: 5px 10px;
}


.video-js {
    width: 100% !important;
    height: auto !important;
    border-radius: 10px;
}

.info-icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 23px;
    vertical-align: baseline;
}

.info-icon {
    width: 20px;
    height: 20px;
    background: url('Images/Info_Icon.png') no-repeat center;
    background-size: contain;
    display: inline-block;
    padding-left: 5px;
}

.info-icon-container .info-text {
    visibility: hidden;
    width: 200px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 15px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: -20px;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    border: 2px solid black;
    border-radius: 15% / 40% 40% 40% 40%;
    font-size: 22px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.info-icon-container .info-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 30px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    transform: skew(-15deg);
}

.info-icon-container .info-text::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 28px;
    border-width: 12px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    transform: skew(-15deg);
}

.info-icon-container:hover .info-text {
    visibility: visible;
    opacity: 1;
}

.bold-text {
    font-weight: bold;
}

/* Styles for collapsible hint */
.collapsible-hint {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.collapsible-hint .hint-title {
    margin-right: 5px;
}

.collapsible-hint .hint-content {
    margin-top: 10px;
}

.message-part {
    margin: 0 0 6px 0; /* Adjust space below the paragraph */
    display: flex;
    flex-direction: column;
}

.message-part.no-flex {
    all: unset; /* This resets all properties to their initial values */
    display: block; /* Or inline if you want it inline */
    margin: 0 0 6px 0; /* Retain the desired margin */
    /* Add any other styles you want to keep */
}

/* Loader Dots */
.loader-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.loader-dots div {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #888; /* Dot color */
    border-radius: 50%;
    animation: dotLoader 0.8s infinite ease-in-out both;
}

.loader-dots div:nth-child(1) {
    animation-delay: -0.3s;
}

.loader-dots div:nth-child(2) {
    animation-delay: -0.15s;
}

.loader-dots div:nth-child(3) {
    animation-delay: 0s;
}

/* Dot Animation */
@keyframes dotLoader {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

/* Additional Styling */
/* Loader Message */
.bot-message.loader {
    background-color: #f1f1f1;
    border-radius: 15px;
    padding: 10px;
    max-width: 150px;
    margin: 10px 0;
    display: block; /* Ensure the loader is treated as a block element */
    clear: both; /* Make sure it starts on a new line */
    float: none; /* Disable any float behavior */
}






